@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets';

// http://www.colourlovers.com/palette/57071/Nineteen_Ways

$colour-whereyoustart: #B6E8F7; // Where You Start
$colour-returnthefavor: #214252; // Return the Favor
$colour-playerhater: #580000; // Playerhater
$colour-blamegame: #943822; // Blame Game
$colour-seekrefuge: #F8D871; // Seek Refuge

// http://www.colourlovers.com/color/22947D/tealo
$colour-tealo: #22947D;

$brand-primary: $colour-whereyoustart;
$brand-success: $colour-tealo;
$brand-info: $colour-returnthefavor;
$brand-warning: $colour-seekrefuge;
$brand-danger: $colour-blamegame;

$jumbotron-hero-from-colour: $colour-playerhater;

@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

@import './src/styles/vendor/bootstrap-custom';

@import './src/styles/components/blog';
@import './src/styles/components/social-links';
@import './src/styles/components/photo-library';
//@import './src/styles/components/btn-breadcrumb';
@import './src/styles/components/sticky-footer';
//@import './src/styles/components/fullscreen-background';
@import './src/styles/components/alerts';
@import './src/styles/components/loader';
@import './src/styles/components/jumbotron';
